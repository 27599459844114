import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {makeStyles} from "@material-ui/styles";
import {Box, Button} from "@material-ui/core";
import {P2, P3, P4} from "../../components/ParagraphTags";
import moment from "moment";
import {Spacer} from "../../components/Spacer";
import {H1, H2, H3} from "../../components/HeadingTags";
import {CreditCard} from "./components/CreditCard";
import {BillingService} from "../../services/BillingService";
import {Loading} from "../../components/Loading";
import {Profile} from '../../models/Profile';
import {History} from '../../models/History';
import {AccessStorage} from '../../storages/AccessStorage'

export type Props = {}

export function Plan(props: Props) {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [canceled, setCanceled] = useState<boolean>(false);
  const [retry, setRetry] = useState<boolean>(false);
  const [blocked, setBlocked] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile>({
    id: 0,
    statusId: 0,
    statusName: '',
    planId: 0,
    planName: '',
    planValue: '',
    paymentMethod: '',
    lastPayment: new Date(),
    nextPayment: new Date(),
    isDueDate: true,
    payerCardBrand: '',
    payerCardNumber: ''
  });
  const [historyList, setPaymentHistory] = useState<History[]>([]);

  let loadProfile = async () => {
    setLoading(true);
    let user = await AccessStorage.get();

    await BillingService.profile(user.id_Login)
      .then(data => {
        setProfile(data.result);
        setActive(data.result.statusId === 1);
        setRetry(data.result.statusId === 2);
        setCanceled(data.result.statusId === 3);
        setBlocked(data.result.statusId === 4);
      }).catch(err => {
        window.location.href = '/account/subcribe';
      });
    //setLoading(false);
  }

  let loadHistories = async () => {
    //setLoading(true);

    let user = await AccessStorage.get();

    await BillingService.histories(user.id_Login)
      .then(data => {
        setPaymentHistory(data.result);
      }).catch(err => {
        //console.log(JSON.stringify(err));
      });
    setLoading(false);
  }

  useEffect(() => {
    moment.locale('pt-br');
    loadProfile().then();
    loadHistories().then();
  }, []);

  let renderCurrentPlan = () => {
    return (
      <Box display={'flex'} flexDirection={'column'}>
        <Spacer />
        <Box className={classes.planContainer} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} style={{flexDirection: 'row', justifyContent: "space-between", width: '100%'}}>
            <Box display={'flex'} style={{flexDirection: 'row', justifyContent: "space-between", width: '100%'}}>
              <H2>{profile.planName}</H2>
              {<Box display={'flex'} style={{flexDirection: 'row'}}>
                <P2 bold={true} color={'#FF2D64'}>R$ {profile.planValue}</P2>
                {profile.planName === 'Anual' && <P2 bold={true} color={'#8B8B8B'}> / ano</P2>}
              </Box>}
            </Box>
          </Box>
          <Spacer size={0.5}/>
          <Box display={'flex'} style={{flexDirection: 'row', justifyContent: "space-between", width: '100%'}}>
            {active && <Box display={'flex'} style={{paddingRight: '20px'}}>
              <P4 color={'#2197C2'}>Próxima cobrança: {moment(profile.nextPayment).format('DD/MM/YYYY')}</P4>
            </Box>}
            {canceled && <Box display={'flex'}>
              <P4 color={'#2197C2'}>Expira
                em: {moment(profile.nextPayment).format('DD/MM/YYYY')}</P4>
            </Box>}
            {active && <Link
              style={{alignItems: 'flex-end'}}
              to={'/account/cancellation'}>
              <P4 color={'#8B8B8B'} style={{textDecorationLine: 'underline'}}>Cancelar</P4>
            </Link>}
            {(retry || blocked) && <Box display={'flex'}>
              <P4 color={'#2197C2'}>Expirado : {moment(profile.nextPayment).format('DD/MM/YYYY')}</P4>
            </Box>}

          </Box>
        </Box>
        {canceled && <Box>
          <Spacer size={2}/>
          <Button
            variant={"contained"}
            color="secondary"
            disableElevation
            onClick={() => {
              window.location.href = '/account/subscribe';
            }}>
            <H2 color={'#FFF'}>Renovar</H2>
          </Button>
        </Box>}
      </Box>)
  }

  let renderPaymentHistory = () => {
    if (historyList.length <= 0) return null;
    let items = [];
    for (let i = 0; i < historyList.length; i++) {
      let pmtItem = historyList[i];
      items.push(
        <Box display={'flex'} flexDirection={'column'} key={i}>
          <hr style={{border: '1px solid #eeeeee'}}/>
          <Spacer size={2}/>
          <Box display={'flex'} style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <Box display={'flex'} flexDirection={'column'} style={{alignItems: 'flex-start'}}>
              <H3>{pmtItem.productName}</H3>
              <P3 color={'#CCCCCC'}>{moment(pmtItem.datePaid).format('DD/MM/YYYY')}</P3>
            </Box>
            <H3>{Intl.NumberFormat('pt-BR', { style : 'currency', currency: 'BRL' }).format(pmtItem.value)}</H3>
          </Box>
        </Box>
      )
    }

    return (
      <Box display={'flex'} flexDirection={'column'}>
        <H2>Histórico de Pagamentos</H2>
        {items}
        <Spacer size={2}/>
      </Box>
    )
  }

  return (
    <Box>
      {renderCurrentPlan()}
      <Spacer size={2}/>
      {!canceled &&
      <CreditCard
        cardBrand={profile.payerCardBrand}
        cardNumber={profile.payerCardNumber}
        planId={profile.planId}
        statusId={profile.statusId}
        creditCardVisible={false}
        renewing={true}/>}
      <Spacer size={2}/>
      <hr style={{border: '1px solid #eeeeee'}}/>
      <Spacer size={2}/>
      {renderPaymentHistory()}
      <Loading loading={loading}/>
    </Box>
  );
}


const useStyles = makeStyles(() => ({
  planContainer: {
    padding: 20,
    alignItems: 'center',
    border: '1px solid #c3c3c3',
    borderRadius: 10
  },
  buttonContainer: {
    border: '1px solid #c3c3c3',
    borderRadius: 10
  }
}));

