import {AxiosMiddleware} from '../middlewares/AxiosMiddleware';

export class SecurityService {
    static decrypt = async (hash: string) => {
        let criteria = {
            body: hash
        };
        const response = await AxiosMiddleware.post('/api/v1/security/decrypt', criteria);
        return response.result;
    };

    static userLogged = async () => {
        const response = await AxiosMiddleware.post('/api/v1/security/getentity');
        return response.result;
    }
}