import React from 'react';
import {Router} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/styles';
import theme from './themes';
import {renderRoutes} from 'react-router-config';
import {createBrowserHistory} from 'history';
import ScrollReset from "./components/ScrollReset";
import GoogleAnalytics from "./components/GoogleAnalytics";
import routes from './routes';
import './App.css';


const history = createBrowserHistory();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <ScrollReset/>
        <GoogleAnalytics/>
        {renderRoutes(routes)}
      </Router>
    </ThemeProvider>
  );
}

export default App;
