import React, {useEffect, useState} from 'react';
import {Spacer} from "../../components/Spacer";
import {PlanSelect} from "./components/PlanSelect";
import {Box} from "@material-ui/core";
import {P3} from "../../components/ParagraphTags";
import {makeStyles} from "@material-ui/styles";
import {BillingService} from "../../services/BillingService";
import {Plan} from '../../models/Plan';
import {Loading} from "../../components/Loading";
import {Profile} from '../../models/Profile';
import {CreditCard} from "./components/CreditCard";
import {AccessStorage} from '../../storages/AccessStorage';
import {H2} from "../../components/HeadingTags";

export type Props = {}

export function Subscribe(props: Props) {
  const classes = useStyles();

  const [plans, setPlans] = useState<Plan[]>([]);
  const [planId, setPlanId] = useState<number>(12);
  const [loading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile>({
    id: 0,
    statusId: 0,
    statusName: '',
    planId: 0,
    planName: '',
    planValue: '',
    paymentMethod: '',
    lastPayment: new Date(),
    nextPayment: new Date(),
    isDueDate: true,
    payerCardBrand: '',
    payerCardNumber: ''
  });
  const [canceled, setCanceled] = useState<boolean>(false);
  const [editCreditCardVisible, setEditCreditCardVisible] = useState<boolean>(false);

  let loadPlans = async () => {
    setLoading(true);
    await BillingService.plans()
      .then(data => {
        setPlans(data);
      }).catch(err => {
        return null;
      });
    setLoading(false);
  }

  let loadProfile = async () => {
    setLoading(true);
    let user = await AccessStorage.get();

    await BillingService.profile(user.id_Login)
      .then(data => {
        //console.log(data);
        setProfile(data.result);
        setCanceled(data.result.statusId === 3);
        setPlanId(data.result.planId);
      }).catch(err => {
        setEditCreditCardVisible(true);
      });
    setLoading(false);
  }

  useEffect(() => {
    loadPlans();
    loadProfile();
  }, []);

  return (
    <Box>
      {!canceled && <P3 style={{textAlign: 'center'}}>
        Assine o Futstat e tenha acesso exclusivo a todas as funcionalidades no nosso App
      </P3>}
      <Spacer size={2}/>
      <PlanSelect
        onSelect={(value => {
          setPlanId(value);
        })}
        plan={plans[0]}
        planIdSelected={planId}/>
      <Spacer size={2}/>
      <PlanSelect
        onSelect={(value => {
          setPlanId(value);
        })}
        plan={plans[1]}
        planIdSelected={planId}/>
      <Spacer size={2}/>
      <hr style={{border: '1px solid #eeeeee'}}/>
      <Spacer/>
      <CreditCard
        cardBrand={profile.payerCardBrand}
        cardNumber={profile.payerCardNumber}
        planId={planId}
        statusId={profile.statusId}
        creditCardVisible={editCreditCardVisible}
        renewing={true}/>
      <Loading loading={loading}/>
    </Box>
  );
}

const useStyles = makeStyles(() => ({}));
