import React from 'react';

export type  Props = {
  size?: number,
  vertical?: boolean
}

export function Spacer(props: Props) {
  let size = 12 * (props.size ? props.size : 1);
  if (props.vertical) {
    return <div style={{width: `${size}px`}}/>
  } else {
    return <div style={{height: `${size}px`}}/>
  }
}
