import React, {useEffect, useState} from 'react';
import {BillingService} from "../../services/BillingService";
import {Loading} from "../../components/Loading";
import {AccessStorage} from '../../storages/AccessStorage';

export function Default() {
    const [loading, setLoading] = useState<boolean>(false);

    let loadProfile = async () => {
        setLoading(true);
        let user = await AccessStorage.get();
        await BillingService.profile(user.id_Login)
        .then(data => {
            setLoading(false);
            window.location.href = '/account/plan';
        }).catch(err => {
            setLoading(false);
            window.location.href = '/account/subscribe';
        });
    }

    useEffect(() => {
        const accessStorage = async () => {
            await AccessStorage.clear();
            await AccessStorage.setHash(window.location.search);
            await AccessStorage.set();
            await loadProfile();
        }

        accessStorage();
      }, []);

    return (<Loading loading={loading}/>);
}