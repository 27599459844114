import React from 'react';
import {Typography} from "@material-ui/core";


export type Props = {
  style?: React.CSSProperties;
  bold?: boolean,
  color?: string
  children?: any
}

export function P1(props: Props) {
  return <Typography variant="body1"  style={{fontWeight: props.bold ? 'bold' : "normal", color: props.color ?  props.color : '#000000', ...props.style}} >
    {props.children}
  </Typography>;
}

export function P2(props: Props) {
  return <Typography variant="body1" style={{fontWeight: props.bold ? 'bold' : "normal", ...props.style}} >
    {props.children}
  </Typography>;
}

export function P3(props: Props) {
  return <Typography variant="body1" style={{...props.style, fontWeight: props.bold ? 'bold' : "normal", ...props.style}} >
    {props.children}
  </Typography>;
}

export function P4(props: Props) {
  return <Typography variant="body1" style={{fontWeight: props.bold ? 'bold' : "normal", ...props.style}} >
    {props.children}
  </Typography>;
}

export function P5(props: Props) {
  return <Typography variant="body1" style={{fontWeight: props.bold ? 'bold' : "normal", ...props.style}} >
    {props.children}
  </Typography>;
}
