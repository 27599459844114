import React, {Fragment, Suspense} from 'react';
import {renderRoutes} from 'react-router-config';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {LinearProgress} from '@material-ui/core';
import {Topbar} from './components';

const useStyles = makeStyles(theme => ({
  content: {
    height: '100%',
    padding: '20px 20px 20px 20px'
  }
}));

const Default = props => {
  const {route} = props;

  const classes = useStyles();

  return (
    <Fragment>
      <Topbar/>
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress/>}>
          {renderRoutes(route.routes)}
        </Suspense>
      </main>
    </Fragment>
  );
};

Default.propTypes = {
  route: PropTypes.object
};

export default Default;
