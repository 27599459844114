import React from 'react';
import {Typography} from "@material-ui/core";


export type Props = {
  style?: any,
  color?: string
  children?: any
}

export function H1(props: Props) {
  return <Typography variant="h1" style={{color: props.color}}>
    {props.children}
  </Typography>;
}

export function H2(props: Props) {
  return <Typography variant="h2" style={{color: props.color}}>
    {props.children}
  </Typography>;
}

export function H3(props: Props) {
  return <Typography variant="h3" style={{color: props.color}}>
    {props.children}
  </Typography>;
}

export function H4(props: Props) {
  return <Typography variant="h4" style={{color: props.color}}>
    {props.children}
  </Typography>;
}

export function H5(props: Props) {
  return <Typography variant="h5" style={{color: props.color}}>
    {props.children}
  </Typography>;
}
