import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {Box, Button, Typography} from "@material-ui/core";
import {P2} from "../../components/ParagraphTags";
import moment from "moment";
import {Spacer} from "../../components/Spacer";
import {H2} from "../../components/HeadingTags";
import {BillingService} from '../../services/BillingService';
import {Loading} from "../../components/Loading";
import {Profile} from '../../models/Profile';
import {AccessStorage} from '../../storages/AccessStorage';

export type Props = {}

export function Cancellation(props: Props) {
  const classes = useStyles();

  const [profile, setProfile] = useState<Profile>({id:0,statusId:0,statusName:'',planId:0,planName:'',planValue:'',paymentMethod:'',lastPayment:new Date(),nextPayment:new Date(),isDueDate:true,payerCardBrand:'',payerCardNumber:''});
  const [loading, setLoading] = useState<boolean>(false);

  let loadProfile = async () => {
    setLoading(true);
    let user = await AccessStorage.get();
    await BillingService.profile(user.id_Login)
      .then(data => {
        setProfile(data.result);
      }).catch(err => {
        alert(JSON.stringify(err));
        //window.location.href = '/account/subscribe';
      });
      setLoading(false);
  }

  const _cancellation = async () => {
    setLoading(true);

    let user = await AccessStorage.get();

    try {
      await BillingService.cancellation(user.id_Login)
      .then(data => {
        window.location.href = '/account/plan';
      }).catch(err => {
        alert(JSON.stringify(err));
      });
    } catch (err) {
      alert(JSON.stringify(err));
    }

    setLoading(false);
  }

  useEffect(() => {
    moment.locale('pt-br');
    loadProfile();
  }, []);

  return (
    <Box>
      <H2>Cancelar assinatura</H2>
      <Spacer size={2}/>
      <P2>Você realmente deseja cancelar sua assinatura?
      </P2>
      <Spacer size={2}/>
      <Box style={{flexDirection: "row", flexWrap: "wrap"}}>
        <P2>
          Cancelando a sua assinatura você não terá mais acesso às funções extras a partir de {moment(profile.nextPayment).format('DD/MM/YYYY')}.
          {/* <b style={{color: '#4FEBFF'}}>{moment(profile.nextPayment).format('DD/MM/YYYY')}.</b> */}
        </P2>
      </Box>
      <Spacer size={2}/>
      <Box style={{flex: 1}}/>
      <Button 
        variant={"contained"} disableElevation
        href={'/account/plan'}
        color="secondary">
        <H2 color={'#FFF'}>Continuar com a assinatura</H2>
      </Button>
      <Spacer size={2}/>
      <Button variant={"contained"}  disableElevation onClick={_cancellation} style={{backgroundColor: '#cccccc'}}>
        <H2 color={'#FFF'}>Confirmar cancelamento</H2>
      </Button>
      <Loading loading={loading}/>
    </Box>
  );
}


const useStyles = makeStyles(() => ({}));
