import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Box, Button} from "@material-ui/core";
import {H1, H2} from "../../components/HeadingTags";
import {Spacer} from "../../components/Spacer";

export function PageNotFound() {
    const classes = useStyles();

    const onClose = () => {
        window.opener = null;
        window.open('', '_self');
        window.close();
    }

    return (
        <Box display={'flex'} flexDirection={'column'} className={classes.general}>
            <Spacer />
            <H2>Página não acessível no momento.</H2>
            <Spacer size={2} />
            <H2>Por favor, tente novamente!</H2>
        </Box>
    );
}

const useStyles = makeStyles(() => ({
    general: {
        textAlign: 'center'
    },
    cancelButton: {
        padding: '13px',
        border: '1px solid #000000',
        borderRadius: '50px',
        color: '#000000',
        backgroundColor: '#ffffff'
      }
  }));
