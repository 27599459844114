import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {AppBar, Toolbar, Container} from '@material-ui/core';
import {H1} from "../../../../components/HeadingTags";
import Background from '../../../../assets/images/creditcard-brand/bg02.jpg';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    borderRadius: '0 0 35px 0',
    backgroundImage: "url(" + { Background } + ")",
    width: '100%'
  }
}));

const Topbar = props => {
  const {className, ...rest} = props;

  const classes = useStyles();

  return (
    // <AppBar
    //   {...rest}
    //   className={clsx(classes.root, className)}
    //   color="primary"
    // >
    //   <Toolbar>
    //     <H1 color={'#FFFFFF'}>Minha conta</H1>
    //   </Toolbar>
    // </AppBar>
    <Container style={{
      backgroundColor: '#FF7F00',
      backgroundImage: `url(${Background})`,
      height: '56px',
      borderRadius: '0 0 35px 0',
      display: 'flex',
      alignItems: 'center',
      marginLeft: 0,
      marginRight: 0
    }}>
      <H1 color={'#FFFFFF'}>Minha conta</H1>
    </Container>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
