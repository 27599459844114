import React from "react";

export type StyleSheetType = {
  [key: string]: React.CSSProperties;
}


export class StyleSheet {
  static create(styleSheet: StyleSheetType): StyleSheetType {
    return styleSheet;
  }
}
