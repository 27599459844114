export default {
  contained: {
    boxShadow: 'none',
    border: 0,
    width: '100%',
    padding: 10,
    borderRadius: 100,
    minHeight: 50, textTransform: 'none',
    fontSize: 15,
    color: '#ffffff',
    fontFamily: 'Montserrat SemiBold'
  }
};
