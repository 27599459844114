import React from 'react';

export type Props = {
  ratio: number,
  brand?: string
}

export class CreditCardBrand extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public static defaultProps = {
    ratio: 1,
    brand: "visa"
  };

  render() {
    if (this.props.brand === "Diners")
      return (
        <img
          alt={this.props.brand}
          src={require(`../../assets/images/creditcard-brand/diners.png`)}
          style={{width: 70 * this.props.ratio, height: 48 * this.props.ratio}}
        />
      )
    else if (this.props.brand === "Master")
      return (
        <img
          alt={this.props.brand}
          src={require(`../../assets/images/creditcard-brand/mastercard.png`)}
          style={{width: 70 * this.props.ratio, height: 48 * this.props.ratio}}
        />
      )
    else if (this.props.brand === "Amex")
      return (
        <img
          alt={this.props.brand}
          src={require(`../../assets/images/creditcard-brand/amex.png`)}
          style={{width: 70 * this.props.ratio, height: 48 * this.props.ratio}}
        />
      )
    else if (this.props.brand === "JCB")
      return (
        <img
          alt={this.props.brand}
          src={require(`../../assets/images/creditcard-brand/jbc.png`)}
          style={{width: 70 * this.props.ratio, height: 48 * this.props.ratio}}
        />
      )
    else
      return (
        <img
          alt={this.props.brand}
          src={require(`../../assets/images/creditcard-brand/visa.png`)}
          style={{width: 70 * this.props.ratio, height: 48 * this.props.ratio}}
        />
      )
  }
}
