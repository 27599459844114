import axios from 'axios';
import AppSetting from "../settings/AppSetting";

export class AuthService {
    static authenticate = async (login: String, password: String) => {
        let criteria = {
            login: login,
            password: password
        };

        try {
            let response = await axios.post(AppSetting.baseUrl + '/api/v1/login', criteria);
            return response.data.result;
        } catch (e) {
            return null;
        }
    };
}
