import {SecurityService} from "../services/SecurityService";

export class AccessStorage {
    static setHash = async (data: string) => {
      let hash = null;
      
      if (data === undefined || data === null || data === '') {
        window.location.href = '/account/not';
        return;
      }

      hash = data.substring(3, data.length);

      if (hash === null || hash === undefined || hash === ''){
        window.location.href = '/account/not';
        return;
      }
      localStorage.setItem('token', JSON.stringify(hash));
    };

   static set = async () => {
      let hash = await SecurityService.decrypt(await AccessStorage.getHash());
        if (hash !== null) {
          let user = { token: hash };
          localStorage.setItem('user', JSON.stringify(user));

          let userLogged = await SecurityService.userLogged();
          if (userLogged !== null) {
            userLogged.token = hash;
            localStorage.setItem('user', JSON.stringify(userLogged));
          }
        }
    };

    static get = async () => {
      let data = localStorage.getItem('user');
      if (data === null) {
        return null;
      }
      return JSON.parse(data);
    };

    static getHash = async () => {
      let data = localStorage.getItem('token');
      if (data === null) {
        return null;
      }
      return JSON.parse(data);
    };
  
    static clear = async () => {
      localStorage.clear();
    };
}
  