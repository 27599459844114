import {AxiosMiddleware} from '../middlewares/AxiosMiddleware';

export class BillingService {
  static plans = async () => {
    const response = await AxiosMiddleware.get('/api/v1/billing/plans');
    return response.result;
  };

  static paymentMethods = async () => {
    const response = await AxiosMiddleware.get('/api/v1/billing/paymentmethods');
    return response.result;
  };

  static order = async (criteria: object) => {
    const response = await AxiosMiddleware.post('/api/v1/billing/order', criteria);
    return response;
  };

  static cancellation = async (loginId: number) => {
    const response = await AxiosMiddleware.post(`/api/v1/billing/cancellation/${loginId}`);
    return response;
  };

  static histories = async (loginId: number) => {
    const response = await AxiosMiddleware.get(`/api/v1/billing/payment/history/${loginId}`);
    return response;
  };

  static profile = async (loginId: number) => {
    const response = await AxiosMiddleware.get(`/api/v1/billing/status/${loginId}`);
    return response;
  };
}
