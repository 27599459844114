import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {Box, Button} from "@material-ui/core";
import {Spacer} from "../../../../components/Spacer";
import {H2, H3} from "../../../../components/HeadingTags";
import {CreditCardBrand} from "../../../../components/CreditCardBrand/CreditCardBrand";
import {TextField} from "../../../../components/TextField";
import {BillingService} from "../../../../services/BillingService";
import {Loading} from "../../../../components/Loading";
import {AccessStorage} from '../../../../storages/AccessStorage';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {P2} from "../../../../components/ParagraphTags";

export type Props = {
  cardNumber: string,
  cardBrand: string,
  planId: number,
  statusId: number,
  creditCardVisible: boolean,
  renewing: boolean
}

export function CreditCard(props: Props) {
  const classes = useStyles();
  const [editCreditCardVisible, setEditCreditCardVisible] = useState<boolean>(false);
  const [cardNumber, setCardNumber] = useState<string>('');
  const [cardHolder, setCardHolder] = useState<string>('');
  const [cardHolderDocument, setCardHolderDocument] = useState<string>('');
  const [cardExpirationDate, setCardExpirationDate] = useState<string>('');
  const [cardSecurityCode, setCardSecurityCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [open, setOpen] = React.useState(false);

  const [isValid, setIsValid] = useState(false);

  let refCardNumber: TextField | null;
  let refCardHolder: TextField | null;
  let refCardHolderDocument: TextField | null;
  let refCardExpirationDate: TextField | null;
  let refCardSecurityCode: TextField | null;

  if (props.statusId === 0 && props.creditCardVisible !== editCreditCardVisible) {
    setEditCreditCardVisible(props.creditCardVisible);
  }

  else if (props.statusId === 4 && props.creditCardVisible === editCreditCardVisible) {
    setEditCreditCardVisible(!props.creditCardVisible);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const _sendOrder = async () => {
    setLoading(true);

    try {
      // @ts-ignore
      

      let paymentCC;
      if (props.statusId === 3 && !editCreditCardVisible) {
        paymentCC = {
          cardUseToken: true
        }
      } else {
        paymentCC = {
          documentCPF: cardHolderDocument,
          cardNumber: cardNumber,
          cardHolder: cardHolder,
          cardExp: cardExpirationDate,
          cardCVV: cardSecurityCode,
          cardSave: 'true'
        }
      }

      let user = await AccessStorage.get();

      let criteria = {
        loginId: user.id_Login,
        login: user.login,
        name: user.name,
        productId: props.planId,
        paymentMethodId: 2,
        customerId: 0,
        paymentCreditCardCriteria: paymentCC
      }

      await BillingService.order(criteria)
        .then(data => {
          window.location.href = '/account/plan';
        })
        .catch(err => {
          //alert(err.message);
          setOpen(true);
        });
      
    } catch (err) {
      alert(JSON.stringify(err));
    }

    setLoading(false);
  }

  useEffect(() => {
    validateFields();
  }, [cardNumber, cardHolder, cardHolderDocument, cardExpirationDate, cardSecurityCode]);

  const validateFields = async() => {
    if (editCreditCardVisible && await refCardNumber?.isValid() 
    && await refCardHolder?.isValid() 
    && await refCardExpirationDate?.isValid() 
    && await refCardSecurityCode?.isValid()
    && await refCardHolderDocument?.isValid()) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  let renderEditCreditCard = () => {
    return (<Box display={'flex'} flexDirection={'column'}>
      <TextField
        ref={component => (refCardNumber = component)}
        label={"Número do Cartão"}
        required={true}
        value={cardNumber}
        mask={"creditCardNumber"}
        onChangeText={setCardNumber}/>
      <Spacer/>
      <TextField
        ref={component => (refCardHolder = component)}
        label={"Nome do Titular"}
        autoCapitalize={"words"}
        required={true}
        value={cardHolder}
        onChangeText={setCardHolder}/>
      <Spacer/>
      <Box display={'flex'} flexDirection={'row'}>
        <Box display={'flex'} flex={1}>
          <TextField
            ref={component => (refCardExpirationDate = component)}
            label={"Validade"}
            required={true}
            mask={"creditCardExpiry"}
            validate={"creditCardExpiry"}
            value={cardExpirationDate}
            onChangeText={setCardExpirationDate}/>
        </Box>
        <Spacer vertical={true}/>
        <Box display={'flex'} flex={1} flexDirection={'column'}>
          <TextField
            ref={component => (refCardSecurityCode = component)}
            label={"CVV"}
            required={true}
            mask={"number"}
            maxLength={4}
            value={cardSecurityCode}
            onChangeText={setCardSecurityCode}/>
        </Box>
      </Box>
      <Spacer/>
      <TextField
        ref={component => (refCardHolderDocument = component)}
        label={"CPF"}
        autoCapitalize={"words"}
        required={true}
        mask={"cpf"}
        validate={"cpf"}
        value={cardHolderDocument}
        onChangeText={setCardHolderDocument}/>
    </Box>)
  }

  let renderCreditCard = () => {
    return (<>
        {!editCreditCardVisible && !!props?.cardNumber &&
        <Box display={'flex'} style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <Box display={'flex'} style={{flexDirection: 'row', alignItems: 'center'}}>
            <CreditCardBrand ratio={0.75} brand={props.cardBrand}/>
            <Spacer vertical={true}/>
            <H2>**** {props.cardNumber}</H2>
          </Box>
          <Box display={'flex'} style={{alignItems: 'flex-end'}} flexDirection={'column'}>
            <Button
              variant={"contained"}
              disableElevation
              onClick={() => {
                setEditCreditCardVisible(true);
              }}
              className={classes.buttonChange}
              color="secondary">
              <H2 color={'#FFF'}>Alterar</H2>
            </Button>
          </Box>
        </Box>}
        {editCreditCardVisible && <Box display={'flex'} flexDirection={'column'}>
          <H2>Dados da Assinatura</H2>
          <Spacer size={2}/>
          {renderEditCreditCard()}
        </Box>}

        {props.statusId === 0 && <Box>
          <Spacer size={2}/>
          <Button variant={"contained"} disableElevation onClick={_sendOrder} color="secondary" disabled={!isValid}>
            Assinar
          </Button>
        </Box>}

        {editCreditCardVisible && (props.statusId === 1 || props.statusId === 2 || props.statusId === 4) && !!props?.cardNumber && <Box>
          <Spacer size={2}/>
          {props.statusId !== 4 && <Box display={'flex'} style={{flexDirection: 'row'}}>
            <Box style={{flex: 1}} flexDirection={'column'}>            
              <Button
                variant={"contained"}
                disableElevation
                onClick={() => {
                  setEditCreditCardVisible(false);
                }}
                className={classes.cancelButton}
                >
                <H2>Cancelar</H2>
              </Button>
            </Box>
            <Spacer vertical={true} size={2}/>
            <Box display={'flex'} style={{flex: 1}} flexDirection={'column'}>
              <Button variant={"contained"} disableElevation onClick={_sendOrder} color="secondary" disabled={!isValid}>
                <H2 color={'#FFF'}>Salvar</H2>
              </Button>
            </Box>
          </Box>}
          {props.statusId === 4 && <Box display={'flex'} style={{flexDirection: 'row'}}>
            <Box style={{flex: 1}} flexDirection={'column'}>
              <Box display={'flex'} style={{flex: 1}} flexDirection={'column'}>
                <Button variant={"contained"} disableElevation onClick={_sendOrder} color="secondary" disabled={!isValid}>
                  <H2 color={'#FFF'}>Salvar</H2>
                </Button>
              </Box>
            </Box>
          </Box>}
        </Box>}

        {props.statusId === 3 && !!props?.cardNumber && <Box>
          <Spacer size={2}/>
          <Box 
            display={'flex'} 
            style={{flexDirection: 'row', justifyContent: 'space-between'}}
          >
            <Box flexDirection={'column'}>
              {editCreditCardVisible &&
              <Button
                //variant={"contained"}
                disableElevation
                onClick={() => {
                  setEditCreditCardVisible(false);
                }}
                className={classes.buttonClose}>
                X
              </Button>}
              {!editCreditCardVisible &&
              <Button
                //variant={"contained"}
                disableElevation
                onClick={() => {
                  window.location.href = '/account/plan';
                }}
                className={classes.buttonClose}>
                <H3>X</H3>
              </Button>}
            </Box>
            <Box flexDirection={'column'}>
              <Button
                variant={"contained"}
                disableElevation
                onClick={_sendOrder}
                className={classes.buttonConfirm}
                color="secondary"
                disabled={editCreditCardVisible && !isValid}
                >
                <H2 color={'#FFF'}>Confirmar renovação</H2>
              </Button>
            </Box>
          </Box>
        </Box>}

        {/* modal */}
        <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="sm"
          aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Futstat
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{color: '#263238'}}>
              Por favor, revise os dados de pagamento.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose} 
            variant={"contained"}
            disableElevation
            style={{backgroundColor: '#ff9700'}}
            >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      </React.Fragment> 
      </>
    )
  }

  return (<Box>
    {renderCreditCard()}
    <Loading loading={loading}/>
  </Box>);
}

const useStyles = makeStyles(() => ({
  buttonChange: {
    paddingLeft: '30px',
    paddingRight: '30px'
  },
  buttonConfirm: {
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  buttonClose: {
    width: 'inherit',
    padding: '13px',
    border: '1px solid #000000',
    borderRadius: '50px',
    color: '#000000'
  },
  cancelButton: {
    padding: '13px',
    border: '1px solid #000000',
    borderRadius: '50px',
    color: '#000000',
    backgroundColor: '#ffffff'
  }
}));
