import React, { useState, useEffect } from 'react';
import {P2, P5} from "../../../../components/ParagraphTags";
import {Box} from "@material-ui/core";
import {H2} from "../../../../components/HeadingTags";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import { Plan } from '../../../../models/Plan';

export type States = {}

export type Props = {
  plan: Plan;
  planIdSelected: number;
  onSelect: (planId: number) => void;
}

export function PlanSelect(props: Props) {
  const classes = useStyles();
  let isActive = props.planIdSelected === props.plan?.id;

  useEffect(() => {

  }, []);

  return (
    <Box onClick={() => {
      props.onSelect(props.plan?.id)
    }} 
    display="flex" 
    flexDirection={'row'} 
    className={clsx(classes.container, isActive ? classes.active : null)}
    style={{
      border: isActive ? 'solid 2px #2197c2' : 'solid 1px #cccccc'
    }}  
    >
      <Box display="flex" alignItems={"center"}>
        <H2>{props.plan?.name}</H2>
      </Box>
      <Box display="flex" flexDirection={'column'} alignItems={'flex-end'} flex={1} justifyContent={'center'}>
        <Box display="flex" flexDirection={'row'}>
          <P2 bold={true} color={'red'}>{props.plan?.value}</P2>
        </Box>
        {props.plan?.name === "Anual" &&
          <Box display={'flex'} flex={1} justifyContent={'flex-end'}>
            <P5 color={'#8B8B8B'}>(Ref R$4.16 / mês)</P5>
          </Box>
        }
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    //border: '2px solid #2da679',
    borderRadius: 10,
    minHeight: 40,
    border: 'solid 1px #cccccc'
  },
  active: {
    backgroundColor: '#eeeeee'
  }
}));
