import React from 'react';
import {Redirect} from 'react-router-dom';
import DefaultLayout from './layouts/Default';
import {Default, Plan, Cancellation, Subscribe, PageNotFound} from "./pages/Account";

const hash = window.location.search;

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/account" />
  },
  {
    path: '/account',
    component: DefaultLayout,
    routes: [
      {
        path: '/account/subscribe',
        exact: true,
        component: Subscribe
      },
      {
        path: '/account/plan',
        exact: true,
        component: Plan
      },
      {
        path: '/account/cancellation',
        exact: true,
        component: Cancellation
      },
      {
        path: '/account/default',
        exact: true,
        component: Default
      },
      {
        path: '/account/not',
        exact: true,
        component: PageNotFound
      },
      {
        component: () => <Redirect to={`/account/default${hash}`} />
      }
    ]
  }
];

export default routes;
